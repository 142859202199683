<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="addDialogVisible = true" :loading="loading">添加</el-button>
      <el-button type="primary" @click="exportData" :loading="loading">全量导出</el-button>
      <el-button type="primary" @click="showImportData">全量覆盖导入</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="repairDemandText" label="是否可维修"></el-table-column>
      <el-table-column align="center" prop="warrantyText" label="买保类型"></el-table-column>
      <el-table-column align="center" prop="warrantyMonth" label="买保时长(月)"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="100"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150"></el-table-column>
      <el-table-column align="center" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button @click="deleteInfo(scope.row)" type="danger" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination
          :total="total"
          layout="total,prev, pager, next"
          background
          :page-size="pageSize"
          @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog title="全量导入维修买保表" :visible.sync="importDialogVisible" :close-on-click-modal="false" width="800px" :before-close="importHandleClose">
      <ImportComponent template-url="template/repairWarranty.xlsx" upload-url="materialRepairWarranty/upload"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importHandleClose">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加维修买保数据" :visible.sync="addDialogVisible" :close-on-click-modal="false" width="600px" :before-close="addHandleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" class="edit-input" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否可维修：">
          <el-radio-group v-model="formData.repairDemand">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="买保类型：">
          <el-radio-group v-model="formData.warranty" @change="warrantyChange">
            <el-radio :label="true">买保</el-radio>
            <el-radio :label="false">未买保</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="买保时长(月)：">
          <el-input v-model="formData.warrantyMonth" type="number" max="10000" min="0" placeholder="请输入买保时长" class="edit-input"></el-input>
        </el-form-item>
      </el-form>
      <div class="tip" style="padding-left: 100px">提示：请本地做好数据保存，以便以后全量覆盖导入联想提供的维修买保表数据</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addHandleClose">关闭</el-button>
        <el-button type="primary" @click="commit" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";

export default {
  name: "MaterialRepairWarranty",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        materialCode: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
        repairDemand: false,
        warranty: false,
        warrantyMonth: 0
      },
      dataList: [],
      total: 0,
      pageSize: 20,
      importDialogVisible: false,
      addDialogVisible: false,
      loading: false,
    }
  },
  created() {
    this.currentChange(1)
  },
  methods: {
    warrantyChange() {
      if (!this.formData.warranty) {
        this.formData.warrantyMonth = 0;
      }
    },
    search() {
      this.loading = true;
      this.$axios.post('materialRepairWarranty/queryPage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.dataList = res.data.records || []
        this.total = res.data.total
        this.pageSize = res.data.size
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    exportData() {
      this.loading = true
      this.$axios.get('materialRepairWarranty/export', {timeout: 600000}).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });

    },
    importHandleClose() {
      this.search();
      this.importDialogVisible = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search();
    },
    showImportData() {
      this.$confirm('覆盖导入将清除之前的所有数据，请确保要导入的Excel包含完整的数据，是否继续？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '继续',
        type: 'warning'
      }).then(() => {
        this.importDialogVisible = true
      })

    },
    deleteInfo(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('materialRepairWarranty/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    addHandleClose() {
      this.currentChange(1)
      this.addDialogVisible = false
    },
    commit() {
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空')
      }
      if (this.formData.warranty === false) {
        this.formData.warrantyMonth = 0;
      }

      this.loading = true;
      this.$axios.post('materialRepairWarranty/add', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.$message.success('保存成功');
        this.addHandleClose()
        this.formData.materialCode = ''
        this.formData.repairDemand = false
        this.formData.warranty = false
        this.formData.warrantyMonth = 0
      }, error => {
        this.loading = false;
        return this.$message.error('保存失败，' + error.message);
      })


    }
  }
}
</script>

<style scoped>
.input {
  width: 180px;
  margin-right: 10px;
}

.edit-input {
  width: 250px;
}
</style>